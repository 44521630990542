/* SliderComponent.css */
.slider-container a {
    text-decoration: none !important;
    color: #000 !important;
    margin: 0 !important;
}

.slider-container {
    max-width: 100%;
    margin: 0 auto; /* Center the slider container */
    padding: 30px 0 !important; /* Adjust padding to avoid horizontal overflow */
    overflow: hidden; /* Prevent overflow */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.slider-item {
    text-align: center !important;
    padding: 10px;
    cursor: pointer;
    border: 0 !important;
}

h1 {
    text-align: center !important;
}

.slider-item img {
    width: 100%;
    height: auto;
}

.slick-slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@media (max-width: 1024px) {
    .slider-container {
        width: 100% !important;
    }
}

@media (max-width: 600px) {
    .slider-item {
        margin: 0 10px;
    }
}
