.product-grid {
  display: flex;
  flex-direction: column;
  align-items: stretch; 
}

.product-grid > * {
  flex: 1; 
}
.product-grid a {
  text-decoration: none; /* Ensures no underline by default */
}
.product-grid a:hover {
  text-decoration: none; /* Ensures no underline on hover */
}
.product-grid .text-element {
  text-decoration: none; /* Ensure no underline by default */
}

.product-grid .text-element:hover {
  text-decoration: none; /* Ensure no underline on hover */
}

@media (min-width: 768px) {
  .product-grid {
    flex-direction: row;
    justify-content: space-around;
  }
}
