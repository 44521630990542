.rootProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  min-height: 100vh;
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.header-root {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 3rem 0 2.5rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.headingProfile {
  text-align: center;
  font-weight: 900 !important;
  font-size: 30px !important;
  text-transform: uppercase;
  color: #414141;
}

.greeting {
  font-size: 16px;
  color: #414141;
  font-weight: 500 !important;
}

.profileContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 97%;
  align-items: flex-start;
}

.leftContainer,
.rightContainer {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 350px;
}

.rightContainer {
  flex: 1;
  margin-left: 2rem;
}

.profileSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  /* padding: 1.5rem 2.5rem 3rem 0.5rem; */
  border-radius: 3px;
  margin-bottom: 1rem;
  text-align: center;
}

.profileAvatar {
  width: 90px !important;
  height: 90px !important;
  margin-bottom: 8px !important;
  font-size: 2rem !important;
  font-weight: 800 !important;
  background-color: #414141 !important;
}

.leftDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.profileHeadingLeft {
  font-size: 1.6rem !important;
  font-weight: 800 !important;
  margin-bottom: 1rem !important;
  color: #212121 !important;
  text-transform: uppercase !important;
}

.profileHeading {
  font-size: 18px !important;
  font-weight: 900 !important;
  margin-bottom: 1rem !important;
  color: #212121 !important;
  text-transform: uppercase !important;
}

.profileSubHeading {
  font-size: 1rem !important;
  color: black !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

.profileText {
  font-size: 1rem !important;
  color: #414141;
  text-transform: uppercase;
}

.profileText2 {
  font-size: 16px;
  color: #414141;
  font-weight: 500 !important;
  text-align: center;
}

.profileText3 {
  font-size: 14px;
  color: black;
  font-weight: 400;
  width: 80%;
  margin-top: -14px;
}

.myOrder {
  width: 100%;
  background-color: white !important;
  text-align: center;
  border-radius: 3px !important;
}

.ordersButton,
.profileButton {
  width: 90%;
  padding: 0.5rem !important;
  margin-bottom: 8px !important;
  background-color: #414141 !important;
  color: #fff !important;
}

.ordersButton:hover,
.profileButton:hover {
  background-color: #9caccc !important;
}

.rightHeadings,
.details,
.manageAccount {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
}

.manageAccount p {
  width: 100%;
}

@media (max-width: 950px) {
  .profileContainer {
    flex-direction: column;
    align-items: center;
  }
  .rightContainer {
    margin-left: 0;
    width: 100%;
  }
  .leftContainer,
  .rightContainer {
    max-width: 100%;
  }
}

@media (max-width: 599px) {
  .profileText2,
  .profileText3 {
    width: 100%;
  }
}
