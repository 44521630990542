/* Main container styling */
.office-search-container {
    min-width: 50%;
    margin: 7.5px auto;
    font-family: Arial, sans-serif;
    position: relative;
  }
  
  /* Search input styling */
  .search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  
  /* Office list dropdown styling */
  .office-list {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1000; /* To ensure it floats above other elements */
  }
  
  /* Individual office item */
  .office-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer; /* Make it clickable */
  }
  
  .office-item:hover {
    background-color: #f9f9f9;
  }
  
  /* Last item without bottom border */
  .office-item:last-child {
    border-bottom: none;
  }
  
  /* Office icon */
  .office-icon {
    margin-right: 10px;
    font-size: 18px;
  }
  
  /* Office details styling */
  .office-details {
    font-size: 14px;
  }
  
  /* Strong tag for the office name */
  .office-details strong {
    font-size: 16px;
  }
  