.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 240px;
  background-color: rgb(37, 37, 37);
   box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  padding-top: 20px;
}

.sidebar-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 10px;
  font-size: 20px;
  color: #f2f2f2;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: larger;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 50%;
}

.sidebar-close-btn:hover {
  background-color: #9caccc;
  transform: scale(1.1);
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 3rem 0 0;

}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
 
}

.sidebar-menu-item:hover {
  background-color: #9caccc;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}

.sidebar-menu-item-text {
  color: #f2f2f5;
  font-size: 16px;
  font-weight: 500;
  margin-left: 1rem;
  text-decoration: none;
  text-decoration-line: none
}

.sidebar-menu-item svg {
  color: #f2f2f5;
  font-size: 26px;
  margin-right: 0.5rem;
}
