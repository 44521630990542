
.product-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.product-card-banner {
  position: relative;
  text-align: center;
  margin: 10px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}
.product-card-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  background: rgba(0, 0, 0, 0.4); 
  z-index: 1;
}
.product-card-banner:hover{
  transform: scale(0.99);
}

.product-title-banner {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
  text-decoration: none;
  z-index: 2;
}

.product-title-banner:hover {
  background: rgba(0, 0, 0, 0.7);
}

.product-image-banner {
  width: 100%;
  height: 450px;
  object-fit: cover;
  object-position:  center ;
  border-radius: 2px;
}
@media (max-width: 768px) {
  .product-image-banner {
    height: 350px; 
  }

  
}
