.product-container {
  display: flex !important;
  flex-direction: column !important;
  padding: 20px !important;
  margin-top: 7.5rem !important;
  justify-content: space-between !important;
}

.image-gallery {
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 20px !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
}

.thumbnails {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important; /* 4 elements per row */
  gap: 10px !important;
  min-width: 60px !important;
  max-height: 260px !important;
  overflow-y: scroll !important;
}

.thumbnails img {
  width: 100% !important;
  height: 60px !important;
  cursor: pointer !important;
  object-fit: cover !important;
}

.main-image {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  object-fit: cover !important;
  cursor: pointer !important;
}

.main-image img {
  max-width: 100% !important;
  height: auto !important;
}

.product-details {
  display: flex !important;
  flex-direction: column !important;
  max-width: 400px !important;
}

.product-details h1 {
  font-size: 24px !important;
  margin-bottom: 10px !important;
}

.product-details p {
  margin: 5px 0 !important;
}

.price {
  font-size: 20px !important;
  margin-bottom: 10px !important;
}

.cta-button {
  background-color: #f3f3f3 !important;
  border: none !important;
  padding: 10px !important;
  cursor: not-allowed !important;
  margin-bottom: 10px !important;
}

.product-description p {
  margin-bottom: 10px !important;
}

.size-chart {
  text-align: center !important;
  margin-bottom: 20px !important;
}

.size-chart h3 {
  margin-bottom: 10px !important;
}

.sizes {
  display: flex !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
}

.size-button {
  color: #888 !important;
  background-color: #ccc !important;
  padding: 10px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  border-radius: 3px !important;
}

.size-button-selected {
  background-color: black !important;
  color: white !important;
}

.size-button:hover {
  color: #fff !important;
}

.product-buttons {
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  justify-content: center !important;
  align-items: center !important;
}

.product-buttons .add-to-cart,
.product-buttons .add-to-wishlist {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 10px !important;
  margin-bottom: 10px !important;
  cursor: pointer !important;
  font-size: 16px !important;
}

.product-buttons .add-to-wishlist {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}

.shipping-info {
  margin-bottom: 20px !important;
}

.shipping-info p {
  margin: 5px 0 !important;
}

.shipping-info a {
  color: #0066c0 !important;
  text-decoration: none !important;
}

.view-details {
  color: #0066c0 !important;
  text-decoration: none !important;
}

@media (min-width: 768px) {
  .product-container {
      flex-direction: row !important;
      justify-content: space-between !important;
  }

  .image-gallery {
      flex-direction: column !important;
  }

  .main-image img {
      max-width: 400px !important;
      height: auto !important;
  }

  .product-details {
      margin-left: 20px !important;
  }
}

@media (min-width: 1200px) {
  .product-container {
      flex-direction: row !important;
      justify-content:space-evenly !important;
  }

  .image-gallery {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
  }

  .main-image {
      margin-left: 40px !important;
  }

  .main-image img {
      min-width: 500px !important;
      height: auto !important;
      object-fit: cover !important;
  }
}

.prod_details_addtocart_btn {
  border-style: 2px solid !important;
  cursor: pointer !important;
  line-height: inherit !important;
  padding: 0px 16px !important;
  position: relative !important;
  text-decoration: none !important;
  text-transform: none !important;
  white-space: nowrap !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: inherit !important;
  min-height: 48px !important;
  background: rgb(22, 22, 22) !important;
  border-color: rgb(19, 19, 19) !important;
  color: rgb(255, 255, 255) !important;
  transition: transform 0.3s ease-in-out 0s !important;
  margin-top: 20px;
}

.see_more_btn {
  border-style: 2px solid !important;
  cursor: pointer !important;
  line-height: inherit !important;
  padding: 0px 16px !important;
  position: relative !important;
  text-decoration: none !important;
  text-transform: none !important;
  white-space: nowrap !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: inherit !important;
  min-height: 48px !important;
  background: #cccccc !important;
  border-color: rgb(19, 19, 19) !important;
  color: #888 !important;
  transition: transform 0.3s ease-in-out 0s !important;
}

.see_more_btn:disabled {
  background-color: #ccc !important;
  color: #888 !important;
  cursor: not-allowed !important;
}

.see_more_btn:hover {
  color: rgb(22, 22, 22) !important;
  background: #f1f1f1 !important;
  transform: scale(1.05) !important;
  border: none !important;
  transition: transform 0.3s ease-in-out 0s !important;
}

.prod_details_addtocart_btn:disabled {
  background-color: #ccc !important;
  color: #888 !important;
  cursor: not-allowed !important;
}

.prod_details_addtocart_btn:hover {
  color: rgb(22, 22, 22) !important;
  background: #f1f1f1 !important;
  transform: scale(1.05) !important;
  border: none !important;
  transition: transform 0.3s ease 0s !important;
}

button {
  cursor: pointer !important;
}

.prod_details_price .tax_txt {
  color: #403e3e !important;
  font-size: .9rem !important;
}

.instock {
  font-weight: 400 !important;
  background-color: #4ed54e40 !important;
  color: #02a102 !important;
  font-size: 12px !important;
  padding: 5px !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
  align-items: center !important;
}

.outofstock {
  background-color: #ff7a7a !important;
  color: white !important;
  font-size: 14px !important;
  padding: 5px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
}

.info-section{
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100%;
}