.banner-link {
  text-decoration: none !important;
  color: inherit !important;
  display: block !important;
}

.banner {
  position: relative !important;
  height: 80vh !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  color: white !important;
  margin: 50px 30px 0 30px !important;
  border-radius: 2px !important;
  overflow: hidden !important;
  cursor: pointer !important;
  transition: 0.3s !important;
  text-decoration: none !important;
}

.banner:hover {
  transform: scale(0.99) !important;
  text-decoration: none !important;
}

.banner-content {
  position: relative !important;
  z-index: 2 !important;
}

.banner::before {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 1 !important;
}

.banner-content h1 {
  font-size: 1.5rem !important;
}

.banner-content p {
  font-size: 1.25rem !important;
}

.banner-content button {
  background-color: rgba(0, 0, 0, 0.7) !important;
  color: white !important;
  padding: 15px 25px !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 1rem !important;
  transition: 0.3s !important;
  border-radius: 2px !important;
}

.banner-content button:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

@media (max-width: 768px) {
  .banner {
    height: 350px !important;
    padding: 10px !important;
  }

  .banner h1 {
    font-size: 1.5rem !important;
  }

  .banner p {
    font-size: 0.875rem !important;
  }

  .banner button {
    padding: 12px 23px !important;
  }
}
