.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  padding: 25px 2px;
  background-color: white;
  margin: 5rem  auto  0;
}

.terms-image {
  width: 70vh;
  height: auto;
  margin-bottom: 20px;
}

.terms-overlay {
 /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
 margin-top: 2%;
  padding: 10px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  z-index: 1;
}

.terms-content {
  font-size: 16px;
  line-height: 1.6;
  color: #333333;
  width: 80%;
 font-family: "Roboto", sans-serif;
 display: block;
 gap: 1rem;
 padding: 23px;
 margin: 0 auto;
 
}

.terms-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 10px;
}

/* Responsive Styles */

@media (max-width: 600px) {
  .terms-overlay {
    font-size: 20px;
  }
  
  .terms-title {
    font-size: 20px;
  }
  
  .terms-content {
    font-size: 14px;
  }
}
