.trending_heading {
  text-align: center;
  margin-top: 2.7rem;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.trending-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.8rem;
  margin: 0 1.8rem;
  padding-bottom: 10%;
}

@media only screen and (min-width: 600px) {
  .trending-products {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 960px) {
  .trending-products {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1200px) {
  .trending-products {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.newArrivals_Grid {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr)); /* 6 items per row for large screens */
  justify-content: center; /* Center items horizontally */
}

@media (max-width: 1200px) {
  .newArrivals_Grid {
    grid-template-columns: repeat(4, minmax(0, 1fr)); /* 4 items per row for mid-size screens */
  }
}

@media (max-width: 768px) {
  .newArrivals_Grid {
    grid-template-columns: repeat(2, minmax(0, 1fr)); /* 2 items per row for small screens */
  }
}
