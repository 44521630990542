.footer {
  background-color: black;
  color: #fff !important;
  padding: 30px 0;
  height: auto;
  overflow-x: hidden !important;
  width: auto;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  text-align: center; /* Center text in footer */
}

.wrapper_footer {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2px;
  margin-top: -30px;
  padding-top: 19px;
  /* padding-left: 11px; */
  overflow-x: hidden;
  width: 100%;
  background-color: hsla(0, 0%, 6%, .95);
  padding-bottom: 15px;
}

.foot_about {
  width: 100%;
  text-align: center; /* Center align text */
}

.foot_logo {
  width: 100%;
  margin: 0 auto; /* Center logo */
}

.Foot_heading {
  font-weight: bolder;
  color: #a29d9d;
  font-size: 24px;
  margin: 0 auto; /* Center heading */
}

.foot_about img {
  width: 50%;
  padding: 0;
  margin: 0 auto; /* Center image */
}

.foot_subs {
  width: 90%;
  margin: 0 auto; /* Center subscription form */
}

.foot_subs h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  width: 100%;
}

.foot_form {
  width: 100%;
}

.input_field_footer {
  color: #f5f1f1;
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  background: #4036368f;
  border: none;
  border-radius: 1px;
  height: 18px;
  outline: none;
}

.input_field_footer::placeholder {
  color: #b7afaf;
}

.foot_subs p {
  font-size: 9px;
  margin-bottom: 10px;
  color: #ab9999;
}

.foot_subs a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.btnFooter {
  border: none;
  color: #222;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 100%;
  border-radius: 1px;
  color: white;
  background: #3b5998;
}

.btnFooter:hover {
  background-color: #9caccc;
  color: rgb(236, 227, 227);
}

.foot_subs_text:hover {
  color: #9caccc;
}

/* Menu section */
.foot_menu_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-gap: 20px;
  width: fit-content;
  margin: 0 auto;
}

.foot_menu h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.foot_menu div {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center; /* Center align menu items */
}

.foot_menu div {
  margin-bottom: 5px;
}

.foot_menu div a {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  font-weight: 500;
}

.foot_menu div a:hover {
  color: #9caccc;
}

/* Links section */
.foot_links {
  width: fit-content;
  margin: 0 auto;
}

.foot_dowload_appLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 43px;
  margin-bottom: 20px;
}

.foot_dowload_appLink h5 {
  font-size: 16px;
  font-weight: bolder;
}

.foot_dowload_appLink span {
  margin-top: -10px;
}

.foot_dowload_appLink img {
  height: 32px;
  /* margin-right: 15px; */
  margin-top: 8px;
}

.app_links {
  display: flex;
  justify-content: center;
  margin-top: -2rem;
}

.foot_social {
  display: flex;
  justify-content: center;
}

.foot_social a {
  color: #fff;
  font-size: 14px;
  /* margin-right: 10px; */
}

/* separator */
.separatorFooter {
  border-top: .7px solid rgba(48, 47, 47, 0.712);
  margin: 0 auto;
}

/* sub_footer */
.sub_footer_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  gap: 5px;
}

.foot_policyLink div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-top: 30px;
  padding-bottom: 10px;
  gap: 10px;
  width: 100%;
}

.foot_policyLink div {
  text-decoration: none;
  list-style: none;
}

.foot_policyLink div * {
  color: white;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
}

.foot_copyright {
  display: flex;
  justify-content: center;
  gap: 5px;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  align-content: center;
}

.foot_copyright a {
  text-decoration: none;
  color: rgba(248, 245, 245, 0.733);
}

.foot_copyright a:hover {
  color: #1DA1F2;
}

/* Media queries */
@media (max-width: 768px) {
  .wrapper_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .foot_logo {
    width: 100%;
  }

  .foot_subs {
    width: 100%;
    text-align: center;
  }

  .foot_subs h5 {
    font-size: 24px;
  }

  .foot_form {
    width: 100%;
  }

  .input_field_footer {
    width: 100%;
    height: 28px;
    font-size: 16px;
    border-radius: 1px;
  }

  .foot_subs p {
    font-size: 16px;
  }

  .btnFooter {
    width: 100%;
    font-size: 21px;
    height: 52px;
    border-radius: 1px;
  }

  .foot_menu_container {
    grid-template-columns: 1fr;
  }

  .foot_menu h4 {
    font-size: 18px;
  }

  .foot_menu ul {
    text-align: center;
  }

  .foot_menu li {
    width: 100%;
    text-align: center;
  }

  .foot_menu li a {
    font-size: 14px;
    font-weight: 400;
  }

  .foot_dowload_appLink h5 {
    font-size: 24px;
  }

  .foot_dowload_appLink {
    margin-top: 30px;
  }

  .foot_social {
    justify-content: center;
  }

  .foot_policyLink ul {
    flex-direction: column;
  }

  .foot_copyright {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 499px) {
  .foot_form .input_field_footer {
    width: 100%;
  }

  .foot_form .btnFooter {
    width: 100%;
  }

  .foot_subs p {
    font-size: 12px;
  }

  .foot_menu_container {
    margin-left: 0;
  }

  /* .foot_menu {
    margin-left: 3px;
  } */

  .foot_menu ul li,
  .foot_social a {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .foot_policyLink ul {
    justify-content: center;
  }

  .foot_policyLink ul li {
    text-align: center;
  }

  .foot_copyright {
    text-align: center;
    font-size: 11px;
  }
}
