/* Cart Icon Styles */
.cartIconWrapper {
  position: relative;
  margin-bottom: 6px;
}

.cartIcon {
  font-size: 1.8rem;
  cursor: pointer;

  color: #000; /* Initial icon color (black) */
  position: relative;
}
.cartIcon > svg {
  font-size: 1.7rem;
}

.cartIcon:hover {
  color: #0E46A3;
}
.cartItemCount {
  position: absolute;
  top: -2px;
  right: -8px;
  background-color: #9caccc;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  padding: 0 5px;
}
